@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body{
  font-family: 'Work Sans', sans-serif;
  overflow-x: hidden;
}
.btn{
  width: 181px;
  height: 48px;
  padding: 0px 14px 0px 14px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  border-radius: 8px;
}
.highlight {
  background-color: #ffffff;
  box-shadow: 0px 4px 40px 0px #64646414;
  border-radius: 10px;
  margin: 3% 0;
}
.question {
  box-shadow: 0px 0px 0px 1px #12693F14;

  box-shadow: 0px 1px 2px 0px #575C5629;
  
  box-shadow: 0px 24px 24px -12px #364A360F;
  gap: 8px;  
}
.sidebar-links li{
  margin: 2% 0;
  border-radius: 8px;
  padding: 1% 3%;
}